import React from "react";
import Landing from "./Components/Landing/Landing";
import FAQs from "./Components/FAQs/FAQs";
import Demo from "./Components/Demo/DemoContainer";
import Kit from "./Components/Kit/Kit.jsx";
import Thanks from "./Components/Demo/Thanks";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import ReactPixel from "react-facebook-pixel";

const history = createBrowserHistory();
const trackingId = "UA-178304597-1";
ReactGA.initialize(trackingId, {
  //obligatorio
  debug: process.env.NODE_ENV === "development" ? true : false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

ReactPixel.init("168293957969418", {
  autoConfig: true,
  debug: process.env.NODE_ENV === "development" ? true : false,
});
ReactPixel.pageView();
ReactPixel.track("PageView", {});

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/faqs" element={<FAQs />} home={false} />
          <Route path="/solicitar-demo" element={<Demo />} />
          <Route path="/thankyou" element={<Thanks />} />
          <Route path="/welcome-kit" element={<Kit />} home={false} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
